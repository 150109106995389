<template>
  <main>
    <trac-back-button class="pb-4">
      <span class=" text-gray-600">
        Back
      </span>
    </trac-back-button>
    <h1 class="text-gray-800 font-semibold capitalize text-sm">
      Fund Wallet Via {{ $route.params.type }} Transfer
    </h1>
    <div
      class="shadow pt-10 pb-40   px-96 border w-full rounded-lg mt-8 flex items-center justify-center"
    >
      <div class="w-full flex items-center flex-col">
        <svg
          class="w-16 h-16"
          viewBox="0 0 104 104"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="104" height="104" rx="8" fill="#EFFAFF" />
          <path
            d="M66 72H38C34.68 72 32 69.32 32 66V38C32 34.68 34.68 32 38 32H66C69.32 32 72 34.68 72 38V66C72 69.32 69.32 72 66 72Z"
            fill="#EFFAFD"
            stroke="#003283"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 52H64"
            stroke="#003283"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 62H64"
            stroke="#003283"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M40 42H64"
            stroke="#003283"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <h1
          class=" text-center mt-3 text-sm text-primaryBlue font-semibold tracking-wide"
        >
          Add Bank Details
        </h1>
        <p class=" text-xs mt-2 text-gray-700">
          Please fill the details below to create a Fixed Wallet Account
        </p>

        <div class="px-10 w-full">
          <trac-input
            class="w-full mt-8"
            type="number"
            placeholder="Account Name"
          ></trac-input>
          <trac-dropdown
            class="w-full mt-5"
            title="Select preferred fixed account Bank"
            :choice="true"
            v-model="selectedBank"
            :menuItems="banks"
          ></trac-dropdown>
          <trac-dropdown
            class="w-full mt-5"
            title="Select Store"
            :choice="true"
            v-model="store"
            :menuItems="stores"
          ></trac-dropdown>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { eventBus } from './../../main';

export default {
  data() {
    return {
      stores: [],
      store: undefined,
      banks: [],
      selectedBank: '',
    };
  },
  async created() {
    const bankList = (await this.$store.dispatch('GET_BANKS')) || [];
    this.banks = Array.isArray(bankList.data) ? bankList.data : [];
    const res = (await this.$store.dispatch('HARD_FETCH_ALL_STORES')) || [];
    if (res.status) {
      this.stores = (res.data || []).map((store) => {
        return { ...store, id: store._id };
      });
    } else {
      eventBus.$emit('trac-alert', { message: res.message });
    }
  },
};
</script>

<style></style>
